// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_d7dc28c","type-body-2":"type-body-2_f411202","type-body-3":"type-body-3_b5bb823","type-body-4":"type-body-4_e564d64","type-section-title":"type-section-title_f2c3256","type-notes":"type-notes_ca6da06","type-item-title":"type-item-title_e33d0f4","type-item-subtitle":"type-item-subtitle_fa05ad8","type-subtitle":"type-subtitle_b42d389","type-title":"type-title_d08ebfe","badge":"badge_f01c13c","static":"static_fb458b3","theme-default":"theme-default_d5720e0","theme-link":"theme-link_ab66365","theme-green":"theme-green_f3791f5","theme-orange":"theme-orange_cc41d27","theme-red":"theme-red_cc9488e","theme-red-solid":"theme-red-solid_eaad4a6","theme-square":"theme-square_b2f7a11","glyph":"glyph_b38d1de","chevron":"chevron_a3c83f0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_d7dc28c\",\"type-body-2\":\"type-body-2_f411202\",\"type-body-3\":\"type-body-3_b5bb823\",\"type-body-4\":\"type-body-4_e564d64\",\"type-section-title\":\"type-section-title_f2c3256\",\"type-notes\":\"type-notes_ca6da06\",\"type-item-title\":\"type-item-title_e33d0f4\",\"type-item-subtitle\":\"type-item-subtitle_fa05ad8\",\"type-subtitle\":\"type-subtitle_b42d389\",\"type-title\":\"type-title_d08ebfe\",\"badge\":\"badge_f01c13c\",\"static\":\"static_fb458b3\",\"theme-default\":\"theme-default_d5720e0\",\"theme-link\":\"theme-link_ab66365\",\"theme-green\":\"theme-green_f3791f5\",\"theme-orange\":\"theme-orange_cc41d27\",\"theme-red\":\"theme-red_cc9488e\",\"theme-red-solid\":\"theme-red-solid_eaad4a6\",\"theme-square\":\"theme-square_b2f7a11\",\"glyph\":\"glyph_b38d1de\",\"chevron\":\"chevron_a3c83f0\"}";
        // 1731684535222
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  