// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","authForm":"authForm_bf62d0e","showHidePass":"showHidePass_e67ad6b","button":"button_a9fe575","input":"input_ec4c594","link":"link_da4d0a5","submitButton":"submitButton_b652c9a","whiteAuthButton":"whiteAuthButton_f17a907","banner":"banner_b73c852","header":"header_ed95439","altLink":"altLink_cd0459b","successText":"successText_ffa9e90","inputGroup":"inputGroup_c2839ba","loadingSpinner":"loadingSpinner_f085ce0","successIcon":"successIcon_b78d807","formErrors":"formErrors_dd89218","altLinks":"altLinks_e894845","poweredBy":"poweredBy_c884fd0","linkTransparent":"linkTransparent_b51d083"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"authForm\":\"authForm_bf62d0e\",\"showHidePass\":\"showHidePass_e67ad6b\",\"button\":\"button_a9fe575\",\"input\":\"input_ec4c594\",\"link\":\"link_da4d0a5\",\"submitButton\":\"submitButton_b652c9a\",\"whiteAuthButton\":\"whiteAuthButton_f17a907\",\"banner\":\"banner_b73c852\",\"header\":\"header_ed95439\",\"altLink\":\"altLink_cd0459b\",\"successText\":\"successText_ffa9e90\",\"inputGroup\":\"inputGroup_c2839ba\",\"loadingSpinner\":\"loadingSpinner_f085ce0\",\"successIcon\":\"successIcon_b78d807\",\"formErrors\":\"formErrors_dd89218\",\"altLinks\":\"altLinks_e894845\",\"poweredBy\":\"poweredBy_c884fd0\",\"linkTransparent\":\"linkTransparent_b51d083\"}";
        // 1731684522622
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  