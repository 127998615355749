// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","inputGroup":"inputGroup_eed6053","checkbox":"checkbox_f0dd915","multiCheckbox":"multiCheckbox_b9caf6a","hasMargin":"hasMargin_b544f6d","textarea":"textarea_a903eda","calendarGroup":"calendarGroup_f0e6a73","dropdown":"dropdown_d863472","dropdownCustom":"dropdownCustom_d54247e","inputGroupSelect":"inputGroupSelect_a8d7b16","inputText":"inputText_b8aad50","dropdownPlaceholder":"dropdownPlaceholder_c2f841c","dropdownMenu":"dropdownMenu_a2a10f0","dropdownBase":"dropdownBase_b89432b","calendarIcon":"calendarIcon_eef62cf","radioGroup":"radioGroup_ddcce7b","radioGroupText":"radioGroupText_c0e0d14"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"inputGroup\":\"inputGroup_eed6053\",\"checkbox\":\"checkbox_f0dd915\",\"multiCheckbox\":\"multiCheckbox_b9caf6a\",\"hasMargin\":\"hasMargin_b544f6d\",\"textarea\":\"textarea_a903eda\",\"calendarGroup\":\"calendarGroup_f0e6a73\",\"dropdown\":\"dropdown_d863472\",\"dropdownCustom\":\"dropdownCustom_d54247e\",\"inputGroupSelect\":\"inputGroupSelect_a8d7b16\",\"inputText\":\"inputText_b8aad50\",\"dropdownPlaceholder\":\"dropdownPlaceholder_c2f841c\",\"dropdownMenu\":\"dropdownMenu_a2a10f0\",\"dropdownBase\":\"dropdownBase_b89432b\",\"calendarIcon\":\"calendarIcon_eef62cf\",\"radioGroup\":\"radioGroup_ddcce7b\",\"radioGroupText\":\"radioGroupText_c0e0d14\"}";
        // 1731684533185
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  